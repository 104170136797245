import React from 'react'
import { makeStyles, Fade, Divider } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { goToCuenta } from '../../api/navigation';

const LOGOUT_TXT = 'Cerrar sesión';

const useStyles = makeStyles(theme => ({
    menuDesktop:{
        '& .MuiPaper-root':{
            top:'3.75rem !important',
            borderRadius:'0.625rem',
            boxShadow:'none !important'
        },
        '& .MuiList-root':{
          border:`1px solid ${theme.palette.grayScale.g700}`,
          borderRadius:'0.625rem' 
        },
        '& .MuiListItem-button:hover':{
          backgroundColor: 'transparent',
          color:`${theme.palette.primary.main2}`
        },
            li:{
                marginTop:'0.625rem'
            },
        '& .MuiMenuItem-root': {
          fontSize:'0.85rem',
          color:`${theme.palette.grayScale.g500}`,
          marginTop:'0.625rem'  
        },
      },
      divider:{
        backgroundColor:`${theme.palette.tertiary.background}`,
        height:'0.031rem',
        margin:'0.625rem 0.625rem',
      },
      lineSeparator: {
        marginLeft: '1rem',
        marginRight: '1rem',
      },
      lineSeparatorSection:{
        marginLeft: '1rem',
        marginRight: '1rem',
        marginBottom:'0.5rem',
      },
      '& .MuiListItem-button:hover': {
        textDecoration: 'none',
        backgroundColor: 'none',
        color:`${theme.palette.primary.main}`
       }, 
       reserveSection:{
          marginTop:'0.725rem'
       },
       mobileLoginSection: {

        color: theme.palette.secondary.main,
      },
      mobileLoginHeader: {
        paddingLeft: '1rem',
        marginTop:'2rem',
        background: `${theme.palette.grayScale.g0}`,
        '& .MuiButtonBase-root': {
          height: 'fit-content',
        }
      },
      mobileLoginContent: {
        position: 'relative',
      },
      welcomeTxt:{
        lineHeight:'1.5rem'
      },
      fullNameTxt:{
        lineHeight:'1.875rem'
      }
}));

export const DesktopUserMenu = ({ anchorEl, closeMenu, logout, menuOptions }) => {
    const classes = useStyles();

  return (
    <Menu
      id="fade-menu"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      open={anchorEl}
      onClose={closeMenu}
      TransitionComponent={Fade}
      className={classes.menuDesktop}
    >
      {menuOptions
          .filter((item) => item.onlyMobile === false)
          .map(({ name, id }) => (
        <MenuItem key={id} id={id} onClick={() => goToCuenta(id)}>{name.desktop}</MenuItem>
      ))}
      <Divider
        className={classes.divider}
        orientation="horizontal"
        variant="fullWidth"
        flexItem
      />
      <MenuItem onClick={logout}>{LOGOUT_TXT}</MenuItem>
    </Menu>
  )
}