export const capitalizeText = (string) => {
  const stringToLowerCase = string.toLowerCase();
  return stringToLowerCase.charAt(0).toUpperCase() + stringToLowerCase.slice(1);
};

/**
 * Obtiene la primer palabra de un texto separado por espacios
 */
export const getFirstWord = ( /** @type {string} */ text) => {
  /** @type {string[]} */
  const words = text.split(' ');

  return words[0];
};

export const capitalizeEachWord = (str) => {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
};
