import { Hidden } from "@material-ui/core"
import React from 'react';

const HiddenOn = ({ children, ...props }) => {
    return props.mobile ?
    (
      <Hidden implementation='css' smDown >
        {children}
      </Hidden>
    ) :
    (
      <Hidden implementation='css' mdUp >
        {children}
      </Hidden>
    )
}

export default HiddenOn